*:focus{
  outline: 0 !important;
}

.custom-nav {
  background-color: #35495e;
  padding-left: 80px;
  padding-right: 80px;
}

.login-sm-container {
  margin-top: 20vh;
}

.loader-parent {
  margin-top: 10vh;
  display: flex;
  justify-content: center;
}

.pwd-instruction-header{
  font-size: small;
  font-weight: bold;
  margin: 5px;
}

.pwd-instruction-list{
  font-size: small;
  margin: 0 auto;
  display: inline-block; 
	text-align: left; 
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: " ";
}
.clearfix::after {
  clear: both;
}

.login-title{
    font-weight: bold;
    font-size: medium;
}

.login-subtitle{
  font-size: medium;
}

.login-form{
    margin-top: 10px;
    padding: 0rem 1rem 1rem 1rem;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .app-card:hover {
    background-color: #dcedc8;
    z-index: 2;
    transition: background-color 500ms;
    cursor: pointer;
  }

  .tab-button:active {
    background-color: #dcedc8;
  }

#email-disabled:hover{
    cursor: pointer;
    color: black;
}
.text-detail{
    margin-top: 12px;
    text-align: left;
    font-size: 14px;
    color: grey;
}

.logo{
    margin: 1rem;
    width: 100px;
}

.ola-logo img {
  width: 70px;
  margin-right: 5px;
}

.country-logo img {
  width: 30px;
}

.market-place {
  padding: 15px
}


.MuiPopover-root .MuiPaper-root{
  padding: 0;
}