@font-face {
  font-family: Gentona;
  src: url('fonts/gentona/Gentona-ExtraLight.otf') format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Gentona;
  src: url('fonts/gentona/Gentona-Light.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Gentona;
  src: url('fonts/gentona/Gentona-Book.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Gentona;
  src: url('fonts/gentona/Gentona-Medium.otf') format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Gentona;
  src: url('fonts/gentona/Gentona-Bold.otf') format("opentype");
  font-weight: 800;
  font-style: normal;
}


@font-face {
  font-family: Gentona;
  src: url('fonts/gentona/Gentona-ExtraBold.otf') format("opentype");
  font-weight: 900;
  font-style: normal;
}

html,
body {
  margin: 0;
  font-family: Gentona !important;
  background-color: #F3F4F5;
}

.navbar.navbar-light .breadcrumb .nav-item.active>.nav-link, .navbar.navbar-light .navbar-nav .nav-item.active>.nav-link{
  background-color: transparent !important;
}

strong {
  font-weight: 600 !important;
}

label {
  font-weight: 400;
}

input[type="password"] {
  letter-spacing: 4px;
  font-weight: bolder;
}

.custom-navbar {
  box-shadow: none !important;
  padding-left: 68px !important;
  padding-right: 68px !important;
  background-color: #fff;
}

@media only screen and (max-width: 400px) {
  .custom-navbar {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

.MuiInputBase-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #F3F4F5 inset;
}

.MuiInputBase-input.Mui-disabled:-webkit-autofill{
  -webkit-box-shadow: 0 0 0px 1000px #F3F4F5 inset;
}

.MuiInputBase-input:-webkit-autofill{
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}