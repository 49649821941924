* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  body {
    padding: 0;
    margin: 0;
  }
  
  #notfound {
    position: relative;
    height: 100vh;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 660px;
    width: 100%;
    padding-left: 160px;
    line-height: 1.1;
  }
  
  .notfound h2 {
    font-size: 24px;
    font-weight: 800 !important;
    padding-top: 8px;
    padding-bottom: 8px;
    text-transform: uppercase;
    color: #151723;
  }

  
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      width: 110px;
      height: 110px;
    }
    .notfound {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 110px;
    }
  }
  * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
    