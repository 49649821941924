
h6 {
    color: #262626;
    margin-top: 10px !important;
    font-weight: 700 !important;
    font-size: 18px !important;
  }

  .card4.my-app {
    height: 90px;
  }

  .card4.other-app {
    height: 132px;
    cursor: default;
  }

  .my-app .text-parent{
    position: absolute;
    bottom: 5px;
  }

  .other-app .text-parent{
    position: absolute;
    bottom: 5px;
  }

  .other-app .text-parent.big-name{
    position: absolute;
    bottom: -5px;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #666666;
  }
  p.small {
    font-size: 14px;
  }
  
  .go-corner {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #00838d;
    border-radius: 0 4px 0 32px;
  }
  
  .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
  }
  
  .card4 {
    display: block;
    top: 0px;
    position: relative;
    width: 240px;
    background-color: #ffffff;
    padding: 12px 20px;
    margin: 8px;
    text-decoration: none;
    overflow: hidden;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px rgba(0, 0, 0, 0.12);
    text-align: left;
  }

  .card4 .go-corner {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background-color: #e6f18c;
    height: 100%;
    width: 24px;
    padding-right: 9px;
    border-radius: 0;
    -webkit-transform: skew(6deg);
            transform: skew(6deg);
    margin-right: -36px;
    -webkit-box-align: start;
            align-items: start;
    background-image: linear-gradient(-45deg, #006D61 1%, #7cb855 100%);
  }
  .card4 .go-arrow {
    -webkit-transform: skew(-6deg);
            transform: skew(-6deg);
    margin-left: -2px;
    margin-top: 9px;
    opacity: 0;
  }
  .card4:hover {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 8px 16px rgba(0, 0, 0, 0.12);
  }
  .card4 h3 {
    margin-top: 8px;
  }
  
  .card4:hover .go-corner {
    margin-right: -12px;
  }
  .card4:hover .go-arrow {
    opacity: 1;
  }

  .card4:hover .name-parent {
    background: linear-gradient(180deg, #3F3A3A 0%, #4F4A4A 100%),
    linear-gradient(180deg, #1B1919 0%, #4B4848 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    
  }